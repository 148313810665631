import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { graphql, withPrefix } from 'gatsby'

import { BsFillXCircleFill } from '../utils/GetIcons'
import Capitalize from '../utils/Capitalize'
import useWindowSize from '../utils/hooks/useWindowSize'
import { POST_TYPE_CONSTANTS } from '../constants'
import { useOnClickOutside } from '../utils/hooks/useOnClickOutside'
import Reveal from '../utils/Reveal'

import Bio from '../components/elements/bio'
import Layout from '../components/layouts/layout'
import Seo from '../components/seo'
import PageHeader from '../components/sections/headers/PageHeader'
import SearchBar from '../components/elements/Searchbar'
import FilterMenu from '../components/postPage/FilterMenu'
import ButtonArrow from '../components/elements/buttons/ButtonArrow'
import PostCard from '../components/elements/cards/PostCard'
import EventCard from '../components/elements/cards/EventCard'
import RouteCard from '../components/elements/cards/RouteCard'

import { BrandButton } from '../components/common/buttons/BrandButton'
import ActiveFilterNumber from '../components/common/ActiveFilterNumber'
import { bounce } from '../styles/Keyframes'

const Texture = styled.div`
  background-image: url(${withPrefix('/images/tilebg.jpg')});
  background-repeat: repeat;
  box-sizing: border-box;
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.greyscale200secondary900TEX};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 3rem 0;
  transition: background-color 300ms ease-in-out;
`

const DropdownButton = styled.button<{ $isActive: boolean }>`
  align-items: center;
  background-color: ${props => props.theme.greyscale100secondary800};
  outline: 2px solid ${props => props.theme.greyscale400to600};
  border: none;
  border-radius: var(--border-radius-2);
  box-shadow: var(--shadow-tight);
  color: ${props => props.theme.black400greyscale300};
  cursor: pointer;
  display: flex;
  font: 500 var(--fontSize-1) / 1.2 var(--font-montserrat);
  justify-content: space-between;
  letter-spacing: var(--letterSpacing-3);
  margin: 1.5rem auto auto auto;
  max-width: 35rem;
  padding: 0.75rem 2rem;
  position: relative;
  transition:
    transform 100ms ease-in-out,
    box-shadow 200ms ease-in-out;
  user-select: none;
  width: 100%;
  &:active {
    transform: scale(0.97);
  }
  @media (min-width: 480px) {
    &:hover {
      box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
    }
  }
  @media (min-width: 980px) {
    display: none;
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      outline-color: var(--color-primary-500);
    `}
`

const ActiveFilterNum = styled(ActiveFilterNumber)`
  right: 4rem;
`

const PostNumber = styled.div`
  align-items: center;
  color: ${props => props.theme.black300greyscale200};
  display: flex;
  justify-content: center;
  font: 300 var(--fontSize-1) / 1.5 var(--font-openSans);
  margin: 0.5rem;
  @media (min-width: 980px) {
    justify-content: start;
    margin: 0.5rem 2rem;
  }
`

const DesktopAligner = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  padding: 0 1rem 1rem 1rem;
  @media (min-width: 480px) {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
  @media (min-width: 768px) {
    padding: 0 2rem 2rem 2rem;
  }
`

const IconContainer = styled.div<{ $open: boolean }>`
  align-items: end;
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--color-greyscale-100);
  cursor: pointer;
  display: flex;
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  height: 30dvh;
  justify-content: center;
  padding-bottom: 1.5rem;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%) ${({ $open }) => ($open ? 'translateY(0)' : 'translateY(-100%)')};
  transition:
    filter 200ms ease-in-out,
    opacity 500ms ease-in-out;
  width: 100%;
  @media (min-width: 480px) {
    &:hover {
      filter: brightness(0.8);
    }
  }
`

const StyledIcon = styled(BsFillXCircleFill)<{ $open: boolean }>`
  animation: ${bounce} 0.9s;
  animation-delay: 0.4s;
  animation-fill-mode: backwards;
  display: ${({ $open }) => ($open ? 'flex' : 'none')};
`

const DropdownPanel = styled.nav<{ $open: boolean }>`
  background-color: ${props => props.theme.greyscale100secondary800};
  border: none;
  bottom: 0;
  box-shadow: 0px -5px 5px -5px black;
  flex-direction: column;
  height: 70dvh;
  overflow-y: auto;
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  padding: 0;
  position: fixed;
  bottom: 0;
  transform: ${({ $open }) => ($open ? 'translateY(0)' : 'translateY(100%)')};
  transition:
    background-color 300ms ease-in-out,
    opacity 500ms ease-in-out,
    transform 500ms ease-in-out;
  will-change: transform;
  width: 100%;
`

const CardGrid = styled.div`
  display: grid;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  grid-gap: 1rem;
  width: 100%;
`

const EmptyText = styled.h2`
  display: flex;
  color: ${props => props.theme.black300greyscale200};
  font: 500 var(--fontSize-5) / 1.5 var(--font-openSans);
  letter-spacing: var(--letterSpacing-5);
  margin: 2rem auto;
`

interface Props {
  data: any
  location: any
  pageContext: any
}

const filterData = (data: any, type: string, filters: any): any => {
  const frontmatterName = type === 'types' ? 'subtype' : type
  if (filters[type] !== undefined && filters[type].length > 0) {
    if (type === 'month') {
      return data.filter((post: any) =>
        filters.month.includes(
          new Date(post.frontmatter.startdate).toLocaleDateString('en-US', { month: 'long' }).toLowerCase()
        )
      )
    } else {
      return data.filter((post: any) => filters[type].includes(post.frontmatter[frontmatterName].toLowerCase()))
    }
  }
  return data
}

const getCard = (post: any, contentType: string): JSX.Element => {
  const cardProps = { key: post.frontmatter.title, post }
  switch (contentType) {
    case 'article':
      return <PostCard {...cardProps} />
    case 'event':
      return <EventCard {...cardProps} />
    case 'route':
      return <RouteCard {...cardProps} />
    default:
      return getZeroData(contentType)
  }
}

const getZeroData = (contentType: string): JSX.Element => (
  <EmptyText key={'zero'}>No {contentType.charAt(0).toUpperCase() + contentType.slice(1)}s Found!</EmptyText>
)

export default function postsPage({ data, location, pageContext }: Props): JSX.Element {
  const { type } = pageContext
  const INITIAL_NUM_CARDS_TO_SHOW = 18
  const INITIAL_FILTERS = {
    continent: [],
    country: [],
    types: [],
    month: [],
  }
  const { nodes } = data.allMarkdownRemark
  const size = useWindowSize()
  const ref = useRef(null)
  const [dropdown, setDropdown] = useState(false)
  const [postNumber, setPostNumber] = useState(data.length)
  const [ascending, setAscending] = useState(false)
  const [currentData, setData] = useState(nodes)
  const [numCardsToShow, setNumCardsToShow] = useState(INITIAL_NUM_CARDS_TO_SHOW)
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const [filterOptions, setFilterOptions] = useState<{
    continents: string[]
    countries: string[]
    types: string[]
  }>({
    continents: [],
    countries: [],
    types: [],
  })

  const combinedLength = Object.values(filters).flat().length
  const isDesktop = Boolean(size.width > 980)
  const filteredData = useMemo(() => {
    return ['types', 'month', 'continent', 'country'].reduce(
      (result, type) => filterData(result, type, filters),
      currentData
    )
  }, [currentData, filters])

  if (type === undefined || nodes.length === 0) {
    return (
      <Layout location={location}>
        <Bio />
        <p>Error. No articles found.</p>
      </Layout>
    )
  }

  const textConstants = POST_TYPE_CONSTANTS[`${type?.toLowerCase()}s` as 'events' | 'articles' | 'routes'] ?? {}
  const title = textConstants?.PAGE_TITLE
  const description = textConstants?.DESCRIPTION

  if (type === 'event') {
    nodes.sort((a: any, b: any) => {
      const dateA = new Date(a.frontmatter.startdate).getTime()
      const dateB = new Date(b.frontmatter.startdate).getTime()
      return dateA - dateB
    })
  }

  const resetFilters = (): void => {
    setFilters(INITIAL_FILTERS)
  }

  const loadMoreCards = (): void => {
    setNumCardsToShow(numCardsToShow + INITIAL_NUM_CARDS_TO_SHOW)
  }

  const reversePosts = (): void => {
    setAscending(!ascending)
  }

  useOnClickOutside(ref, () => {
    setDropdown(false)
  })

  useEffect(() => {
    if (isDesktop && dropdown) setDropdown(false)
  }, [isDesktop])

  useEffect(() => {
    document.body.style.overflow = dropdown ? 'hidden' : 'auto'
    return (): void => {
      document.body.style.overflow = 'auto'
    }
  }, [dropdown])

  useEffect(() => {
    const continentArray: string[] = []
    const countryArray: string[] = []
    const eventTypeSet = new Set<string>()

    nodes.forEach((node: any) => {
      const eventType = node.frontmatter.subtype?.toLowerCase()
      if (eventType !== undefined) eventTypeSet.add(eventType)

      const continent = node.frontmatter.continent.toLowerCase()
      if (!continentArray.includes(continent)) continentArray.push(continent)
      if (type === 'route') {
        const countries = node.frontmatter.countries.map((country: string) => country.toLowerCase())
        countries.forEach((country: string) => {
          if (!countryArray.includes(country)) countryArray.push(country)
        })
      } else {
        const country = node.frontmatter.country.toLowerCase()
        if (!countryArray.includes(country)) countryArray.push(country)
      }
    })
    continentArray.sort()
    countryArray.sort()
    const eventTypesArray = Array.from(eventTypeSet).sort()

    setFilterOptions(prevFilters => ({
      ...prevFilters,
      continents: continentArray,
      types: eventTypesArray,
      countries: countryArray,
    }))
  }, [nodes, type])

  useEffect(() => {
    handlePostNumber(filteredData.length)
  }, [filteredData])

  const getMenu = useMemo(() => {
    const getMenuContent = (): JSX.Element => (
      <FilterMenu
        ascending={ascending}
        filterOptions={filterOptions}
        filters={filters}
        setFilters={setFilters}
        setDropdown={setDropdown}
        resetFilters={resetFilters}
        reversePosts={reversePosts}
      />
    )
    return getMenuContent
  }, [ascending, filters, reversePosts, setFilters])

  const handleData = useCallback((newData: any) => {
    setData(newData)
  }, [])

  const handlePostNumber = useCallback((newPostNumber: any) => {
    setPostNumber(newPostNumber)
  }, [])

  const getPosts = (): JSX.Element[] => {
    if (filteredData.length === 0) return [getZeroData(type)]
    const slicedData = filteredData.slice(0, numCardsToShow)
    const sortedData = ascending ? slicedData.reverse() : slicedData
    return sortedData.map((post: any) => (
      <Reveal effect="fade-up" key={JSON.stringify(post.frontmatter.title)}>
        {getCard(post, type)}
      </Reveal>
    ))
  }

  const posts = useMemo(() => getPosts(), [filteredData, type, ascending, numCardsToShow])

  return (
    <Layout location={location}>
      <Texture>
        <Wrapper>
          <PageHeader title={title} description={description}>
            <SearchBar dataset={nodes} setData={handleData} type={type} />
            <DropdownButton
              role="button"
              aria-expanded={dropdown ? 'true' : 'false'}
              $isActive={Boolean(combinedLength > 0)}
              onClick={() => {
                setDropdown(prev => !prev)
              }}
            >
              {type === 'articles' ? 'SORT & FILTER' : 'FILTER'}
              <ActiveFilterNum $isPop={false} $isExit={combinedLength <= 0}>
                {combinedLength}
              </ActiveFilterNum>
              <ButtonArrow flip={dropdown} />
            </DropdownButton>
          </PageHeader>
          <PostNumber>
            Showing {postNumber < numCardsToShow ? postNumber : numCardsToShow} of {postNumber} {Capitalize(type)}s
          </PostNumber>
          <DesktopAligner>
            {Boolean(size.width) && size.width >= 980 && getMenu()}
            <div style={{ width: '100%' }}>
              <CardGrid>{posts}</CardGrid>
              {numCardsToShow < currentData.length && numCardsToShow < postNumber && (
                <BrandButton onClick={loadMoreCards}>Load More</BrandButton>
              )}
            </div>
          </DesktopAligner>
          {Boolean(size.width) && size.width < 980 && (
            <>
              <IconContainer $open={dropdown}>
                <StyledIcon size={40} $open={dropdown} />
              </IconContainer>
              <DropdownPanel ref={ref} $open={dropdown}>
                {getMenu()}
              </DropdownPanel>
            </>
          )}
        </Wrapper>
      </Texture>
    </Layout>
  )
}

export function Head({ location }: any): JSX.Element {
  const path = Capitalize(location.pathname.split('/')[1])
  return (
    <Seo title={path} description={'Dive into our latest travel stories, tips, and experiences.'} location={location} />
  )
}

export const pageQuery = graphql`
  query postsQuery($type: String, $skip: Int, $limit: Int) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: $type } } }
      sort: { frontmatter: { date: DESC } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          startdate(formatString: "MMMM DD, YYYY")
          enddate(formatString: "MMMM DD, YYYY")
          title
          aka
          description
          type
          subtype
          duration
          city
          country
          countries
          continent
          hero_image_credit_text
          hero_image_credit_link
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
