import React, { useLayoutEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

interface RevealProps {
  children: React.ReactNode
  effect: 'fade-up' | 'fade-in' | 'fade-left' | 'scale-up' | 'pulse-in'
  delay?: number
}

const RevealWrapper = styled.div<{ $effect: string; $revealed: boolean }>`
  opacity: 0;
  transition:
    opacity 0.6s ease-out,
    transform 0.6s cubic-bezier(0, 0, 0.6, 1.5);
  will-change: transform;

  ${({ $effect, $revealed }) => {
    if ($effect === 'fade-up') {
      return $revealed
        ? css`
            transform: translateY(0);
            opacity: 1;
          `
        : css`
            transform: translateY(2rem);
          `
    } else if ($effect === 'fade-in') {
      return $revealed
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `
    } else if ($effect === 'fade-left') {
      return $revealed
        ? css`
            transform: translateX(0);
            opacity: 1;
          `
        : css`
            transform: translateX(2rem);
          `
    } else if ($effect === 'scale-up') {
      return $revealed
        ? css`
            transform: scale(1);
            opacity: 1;
          `
        : css`
            transform: scale(0.25);
          `
    } else if ($effect === 'pulse-in') {
      return $revealed
        ? css`
            transform: scale(1);
            opacity: 1;
          `
        : css`
            transform: scale(0.7);
          `
    }
    return ''
  }}
`

const handleIntersection = (
  entries: IntersectionObserverEntry[],
  revealRef: React.RefObject<HTMLDivElement>,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAboveFold: React.Dispatch<React.SetStateAction<boolean>>,
  delay: number
): void => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const revealWithDelay = (): void => {
        setIsVisible(true)
      }

      if (delay > 0) {
        setTimeout(revealWithDelay, delay)
      } else {
        revealWithDelay()
      }
    } else {
      setIsAboveFold(entry.boundingClientRect.top < window.innerHeight)
    }
  })
}

const Reveal = React.memo(({ children, effect, delay = 0 }: RevealProps) => {
  Reveal.displayName = 'Reveal'
  const [isVisible, setIsVisible] = useState(false)
  const [isAboveFold, setIsAboveFold] = useState(false)
  const revealRef = useRef<HTMLDivElement>(null)
  const revealDelay = delay * 225

  useLayoutEffect(() => {
    const revealElement = revealRef.current
    if (revealElement == null) return

    const isElementAboveFold = revealElement.getBoundingClientRect().top < window.innerHeight
    setIsAboveFold(isElementAboveFold)

    if (isElementAboveFold) {
      return
    }

    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        handleIntersection(entries, revealRef, setIsVisible, setIsAboveFold, revealDelay)
      },
      { threshold: 0.7 }
    )
    observer.observe(revealElement)

    return () => {
      observer?.disconnect()
    }
  }, [isAboveFold, delay])

  if (isAboveFold) {
    return <>{children}</>
  }

  return (
    <RevealWrapper ref={revealRef} $effect={effect} $revealed={isVisible}>
      {children}
    </RevealWrapper>
  )
})

export default Reveal
