import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import ButtonArrow from '../elements/buttons/ButtonArrow'
import Capitalize from '../../utils/Capitalize'
import { Scrollbar } from '../common/Scrollbar'
import { FilterCategory, FilterItem } from '../common/buttons/FilterButtons'
import { PopUp } from '../../styles/Keyframes'

const SelectorList = styled.div`
  border-radius: var(--border-radius-3);
  height: 100%;
  letter-spacing: var(--letterSpacing-3);
  list-style: none;
  list-style-type: none;
  margin: 0.5rem 0 1.5rem 0;
  padding: 0 0.5rem;
  user-select: none;
  width: 100%;
`

const DropdownList = styled.div<{ $open: boolean }>`
  ${Scrollbar}
  list-style: none;
  margin: auto;
  max-height: ${({ $open }) => ($open ? '12rem' : '0')};
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  overflow-y: auto;
  scrollbar-gutter: stable;
  transition:
    background-color 300ms ease-in-out,
    max-height 500ms ease-in-out,
    opacity 500ms ease-in-out;
  z-index: 999;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }
`

const StyledCheckbox = styled.input`
  align-items: center;
  appearance: none;
  display: flex;
  justify-content: center;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-primary-400);
  border-radius: 5px;
  &::after {
    content: '';
    border-radius: 5px;
    justify-content: center;
    width: 12px;
    height: 12px;
    margin: 0;
  }
  &:focus,
  &:hover {
    background-color: var(--color-primary-400);
    opacity: 0.5;
  }
  &:checked {
    background-color: var(--color-primary-400);
    opacity: 1;
  }
  &:checked::after {
    animation: ${PopUp} 0.35s ease-out forwards;
    content: '✓';
    color: var(--color-greyscale-100);
    align-items: center;
    display: flex;
    font-weight: 700;
    justify-content: center;
  }
`

interface Props {
  sectionHeading: string
  filters: any
  setFilters: any
  submenu: any
}

export default function FilterDropdown({ sectionHeading, filters, setFilters, submenu }: Props): JSX.Element {
  const [dropdown, setDropdown] = useState(true)
  const [selectedFilters, setSelectedFilters] = useState(filters)

  useEffect(() => {
    setSelectedFilters(filters)
  }, [filters])

  useEffect(() => {
    setFilters(selectedFilters)
  }, [selectedFilters, setFilters])

  function addOrRemove(item: string, type: string): void {
    setSelectedFilters((prevFilters: any) => {
      const newFilters = { ...prevFilters }
      const index = newFilters[type].indexOf(item)
      if (index === -1) {
        newFilters[type].push(item)
      } else {
        newFilters[type].splice(index, 1)
      }
      return newFilters
    })
  }

  const handleCheckboxClick = useCallback(
    (title: string, type: string): void => {
      addOrRemove(title.toLowerCase(), type.toLowerCase())
    },
    [addOrRemove]
  )

  return (
    <SelectorList>
      <FilterCategory
        key={sectionHeading}
        onClick={() => {
          setDropdown(prev => !prev)
        }}
      >
        {sectionHeading === 'Types' ? 'Event Type' : sectionHeading} <ButtonArrow flip={dropdown} />
      </FilterCategory>
      <DropdownList $open={dropdown} className={`dropdown ${dropdown ? 'show' : ''}`}>
        {submenu.map((item: any, index: number) => (
          <FilterItem
            key={item}
            tabIndex={0}
            onKeyDown={e => {
              e.key === 'Enter' && handleCheckboxClick(item, sectionHeading)
            }}
          >
            {item === 'uk' || item === 'usa' ? item.toUpperCase() : Capitalize(item)}
            <StyledCheckbox
              style={{ cursor: 'pointer' }}
              tabIndex={-1}
              type="checkbox"
              name={item}
              id={item}
              checked={filters[sectionHeading.toLowerCase()].includes(item.toLowerCase())}
              onChange={() => {
                handleCheckboxClick(item, sectionHeading)
              }}
            />
          </FilterItem>
        ))}
      </DropdownList>
    </SelectorList>
  )
}
