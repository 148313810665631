import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useLocation } from '@reach/router'

import useWindowSize from '../../utils/hooks/useWindowSize'
import FilterDropdown from './Dropdown'
import { MONTHS_LONG } from '../../constants'
import { FilterItem } from '../common/buttons/FilterButtons'
import ActiveFilterNumber from '../common/ActiveFilterNumber'
import { PopUp } from '../../styles/Keyframes'

const MenuContainer = styled.div`
  list-style: none;
  list-style-type: none;
  margin: 0 auto;
  max-width: 30rem;
  transition: background-color 300ms ease-in-out;
  @media (min-width: 980px) {
    background-color: ${props => props.theme.greyscale100secondary800};
    box-sizing: border-box;
    box-shadow: var(--shadow-tight);
    display: inline-block;
    flex-direction: column;
    gap: 2rem;
    margin: 0 2rem 0 0;
    min-width: 20rem;
    padding-bottom: 3rem;
    width: calc(20rem + 5vw);
  }
`

const SectionHeading = styled.div`
  align-items: center;
  border-bottom: 1px solid grey;
  color: ${props => props.theme.black300greyscale200};
  display: flex;
  font: 500 var(--fontSize-2) / 1.5 var(--font-montserrat);
  justify-content: center;
  letter-spacing: var(--letterSpacing-4);
  margin: 0;
  padding-top: 1rem;
  position: relative;
  text-align: center;
  transition:
    background-color 300ms ease-in-out,
    color 300ms ease-in-out;
  user-select: none;
  width: 100%;
`

const SelectorList = styled.div`
  border-radius: var(--border-radius-3);
  height: 100%;
  letter-spacing: var(--letterSpacing-3);
  list-style: none;
  list-style-type: none;
  margin: 0.5rem 0;
  user-select: none;
  width: 100%;
`

const SettingsButton = styled.button<{ $isActive: boolean }>`
  align-items: center;
  background-color: transparent;
  border: 0.1rem solid grey;
  border-radius: var(--border-radius-1);
  box-shadow: var(--shadow-tight);
  box-sizing: border-box;
  color: ${props => props.theme.greyscale600to400};
  display: flex;
  font: 500 var(--fontSize-1) / 1 var(--font-openSans);
  justify-content: center;
  letter-spacing: var(--letterSpacing-3);
  margin: 1rem 1rem 1rem 0.5rem;
  padding: var(--spacing-4) var(--spacing-6);
  text-transform: uppercase;
  user-select: none;
  width: 100%;
  @media (max-width: 979px) {
    &:first-of-type {
      margin: 1rem 0.5rem 1rem 1rem;
    }
  }
  @media (min-width: 980px) {
    margin: 1rem auto;
    padding: var(--spacing-3) var(--spacing-6);
    width: 50%;
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      border-color: var(--color-primary-500);
      color: ${props => props.theme.black300greyscale200};
      cursor: pointer;
      &:focus {
        outline: 2px solid ${props => props.theme.primary700to300};
        outline-offset: 0.1rem;
      }
      @media (min-width: 480px) {
        &:hover {
          box-shadow:
            var(--shadow-tight),
            inset 0 0 100px rgba(0, 0, 0, 0.05);
        }
      }
      &:active {
        -webkit-box-shadow: inset 0px 0px 5px black;
        -moz-box-shadow: inset 0px 0px 5px black;
        box-shadow: var(--shadow-button-1);
      }
    `}
`

const ItemLabel = styled(FilterItem)`
  padding: 0.5rem 1rem;
`

const StyledRadioButton = styled.input`
  align-items: center;
  appearance: none;
  display: flex;
  justify-content: center;
  margin: 0 1rem 0 0;
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-primary-400);
  border-radius: 50%;
  transition:
    background-color 200ms ease-in-out,
    opacity 200ms ease-in-out;
  &::after {
    content: '';
    border-radius: 50%;
    justify-content: center;
    width: 12px;
    height: 12px;
    margin: 0;
    transition:
      background-color 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
  }
  @media (min-width: 480px) {
    &:hover::after {
      background-color: var(--color-primary-400);
      opacity: 0.5;
      transition:
        background-color 200ms ease-in-out,
        opacity 200ms ease-in-out;
    }
  }
  &:checked::after {
    animation: ${PopUp} 0.35s ease-out forwards;
    background-color: var(--color-primary-400);
    opacity: 1;
  }
`

interface Props {
  ascending: boolean
  filterOptions: any
  reversePosts: () => void
  filters: any
  setFilters: any
  resetFilters: any
  setDropdown: any
}

export default function FilterMenu({
  filterOptions,
  ascending,
  reversePosts,
  filters,
  setFilters,
  resetFilters,
  setDropdown,
}: Props): JSX.Element {
  const location = useLocation()
  const size = useWindowSize()
  const type: string = location.pathname.split('/')[1]
  const [combinedLength, setCombinedLength] = useState(0)
  const { continents, types, countries } = filterOptions

  useEffect(() => {
    setCombinedLength(Object.values(filters).flat().length)
  }, [filters])

  const handleChange = (): void => {
    reversePosts()
  }

  function getSortButton(title: string, asc: boolean): JSX.Element {
    return (
      <ItemLabel>
        {title}
        <StyledRadioButton
          style={{ cursor: 'pointer' }}
          type="radio"
          name="sort"
          id={title}
          defaultChecked={asc}
          onChange={handleChange}
          tabIndex={0}
        />
      </ItemLabel>
    )
  }

  function getResetButton(): JSX.Element {
    const isActive = Boolean(combinedLength > 0)
    return (
      <SettingsButton
        $isActive={isActive}
        tabIndex={isActive ? 0 : -1}
        onClick={() => {
          resetFilters()
        }}
      >
        RESET
      </SettingsButton>
    )
  }

  function getSettings(): JSX.Element {
    if (size.width < 980) {
      return (
        <div style={{ display: 'flex' }}>
          {getResetButton()}
          <SettingsButton
            $isActive={true}
            onClick={() => {
              setDropdown(false)
            }}
          >
            APPLY
          </SettingsButton>
        </div>
      )
    } else {
      return getResetButton()
    }
  }

  return (
    <MenuContainer>
      <div>
        {type === 'articles' && (
          <>
            <SectionHeading>Sort</SectionHeading>
            <SelectorList>
              {getSortButton('Newest to Oldest', !ascending)}
              {getSortButton('Oldest to Newest', ascending)}
            </SelectorList>
          </>
        )}
        <SectionHeading>
          Filter
          <ActiveFilterNumber key={combinedLength} $isExit={combinedLength === 0} $isPop={combinedLength > 1}>
            {combinedLength}
          </ActiveFilterNumber>
        </SectionHeading>
        {getSettings()}
        {type === 'events' && (
          <>
            <FilterDropdown sectionHeading="Month" filters={filters} setFilters={setFilters} submenu={MONTHS_LONG} />
            <hr />
          </>
        )}
        {type === 'events' && (
          <>
            <FilterDropdown sectionHeading="Types" filters={filters} setFilters={setFilters} submenu={types} />
            <hr />
          </>
        )}
        {continents !== null && continents !== undefined && (
          <>
            <FilterDropdown sectionHeading="Continent" filters={filters} setFilters={setFilters} submenu={continents} />
            <hr />
          </>
        )}
        {type !== 'routes' && (
          <>
            <FilterDropdown sectionHeading="Country" filters={filters} setFilters={setFilters} submenu={countries} />
            <hr />
          </>
        )}
      </div>
    </MenuContainer>
  )
}
