import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const CoverImage = styled(GatsbyImage)`
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  @media (min-width: 480px) {
    transition:
      filter 500ms ease-in-out,
      transform 500ms ease-in-out;
    will-change: transform;
  }
`

export default CoverImage
