import styled from 'styled-components'
import { scaleIn, scaleOut, AbsolutePopUp } from '../../styles/Keyframes'

const ActiveFilterNumber = styled.div<{ $isExit: boolean; $isPop: boolean }>`
  animation: ${({ $isExit, $isPop }) => ($isExit ? scaleOut : $isPop ? AbsolutePopUp : scaleIn)}
    ${({ $isPop }) => ($isPop ? '0.25s' : '0.35s')} ease-out forwards;
  align-items: center;
  background-color: var(--color-primary-700);
  border-radius: 50%;
  color: whitesmoke;
  display: flex;
  justify-content: center;
  height: 1.75rem;
  font: 500 var(--fontSize-1) / 1 var(--font-montserrat);
  right: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.75rem;
`

export default ActiveFilterNumber
