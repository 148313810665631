import { css } from 'styled-components'

export const Scrollbar = css`
  &::-webkit-scrollbar {
    border-radius: 0;
    height: 0.3rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-greyscale-500);
    border-radius: var(--border-radius-1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`
