import React, { useState } from 'react'
import styled from 'styled-components'
import { BsSearch } from '../../utils/GetIcons'

const SearchbarContainer = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
`

const StyledSearchIcon = styled(BsSearch)`
  color: ${props => props.theme.greyscale500secondary200};
  left: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const SearchInput = styled.input`
  background-color: ${props => props.theme.greyscale100secondary800};
  border-radius: 20rem;
  border: none;
  box-shadow: var(--shadow-tight);
  color: ${props => props.theme.black400greyscale300};
  caret-color: ${props => props.theme.primary700to300};
  display: flex;
  font: 500 var(--fontSize-1) / 1.2 var(--font-openSans);
  margin: 0.5rem auto;
  outline: 2px solid ${props => props.theme.greyscale400to600};
  padding: 0.65rem 3rem;
  transition: outline 0.3s ease-out;
  width: 100%;
  max-width: 30rem;
  &:focus {
    outline: 2px solid ${props => props.theme.primary700to300};
    outline-offset: 0.1rem;
  }
  &::placeholder {
    color: ${props => props.theme.greyscale500secondary200};
  }
  @media (min-width: 980px) {
    padding: 0.75rem 3rem;
  }
`

interface Props {
  dataset: any
  setData: any
  type: string
}

function filterData(dataset: any[], searchText: string): any[] {
  return dataset.filter((data: any) => {
    const lowerCaseSearchText = searchText.toLowerCase()
    const titleMatch = data.frontmatter.title?.toLowerCase().includes(lowerCaseSearchText)
    const aka = data.frontmatter.aka?.toLowerCase().includes(lowerCaseSearchText)
    const dateMatch = data.frontmatter.date?.toLowerCase().includes(lowerCaseSearchText)
    const durationMatch = data.frontmatter.duration?.toLowerCase().includes(lowerCaseSearchText)
    const cityMatch = data.frontmatter.city?.toLowerCase().includes(lowerCaseSearchText)
    const countryMatch = data.frontmatter.country?.toLowerCase().includes(lowerCaseSearchText)
    const descriptionMatch = data.frontmatter.description?.toLowerCase().includes(lowerCaseSearchText)
    const countriesMatch = data.frontmatter.countries?.some((country: string) =>
      country.toLowerCase().includes(lowerCaseSearchText)
    )
    return (
      titleMatch === true ||
      aka === true ||
      cityMatch === true ||
      countryMatch === true ||
      countriesMatch === true ||
      dateMatch === true ||
      descriptionMatch === true ||
      durationMatch === true
    )
  })
}

export default function SearchBar({ dataset, setData, type }: Props): JSX.Element {
  const [searchInput, setSearchInput] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const input = e.target.value.toLowerCase()
    setSearchInput(input)
    const filteredData = input !== '' && input.length > 0 ? filterData(dataset, input) : dataset
    setData(filteredData)
  }

  return (
    <SearchbarContainer>
      <StyledSearchIcon size={20} />
      <SearchInput
        type="text"
        id="search"
        placeholder={`Search ${type}s`}
        onChange={e => {
          handleChange(e)
        }}
        value={searchInput}
      />
    </SearchbarContainer>
  )
}
