import * as React from 'react'
import styled from 'styled-components'
import HeaderDescription from '../../common/text/HeaderDescription'

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1.5rem;
  user-select: none;
  margin-top: 2rem;
  @media (min-width: 980px) {
    margin-top: 6rem;
  }
`

const PageTitle = styled.h1`
  color: ${props => props.theme.black200greyscale100};
  font: 900 var(--fontSize-7) / 1 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-1);
  margin: 0 auto;
  text-align: center;
  transition: color 300ms ease-in-out;
  @media (min-width: 980px) {
    font-size: var(--fontSize-8);
  }
`

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5rem;
  padding: 0;
`

interface Props {
  children?: any
  title: string
  description?: string
}

export default function PageHeader({ children, title, description }: Props): JSX.Element {
  return (
    <HeaderContainer>
      <PageTitle>{title}</PageTitle>
      {Boolean(description) && <HeaderDescription>{description}</HeaderDescription>}
      {Boolean(children) && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </HeaderContainer>
  )
}
