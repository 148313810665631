import { Link } from 'gatsby'
import styled from 'styled-components'

const CardContainer = styled(Link)`
  border-radius: var(--border-radius-4);
  box-shadow: var(--shadow-tight);
  box-sizing: border-box;
  display: flex;
  list-style: none;
  list-style-type: none;
  margin: auto;
  max-width: 40rem;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-decoration: none;
  user-select: none;
  width: 100%;
  &:focus {
    outline: 2px solid ${props => props.theme.primary700to300};
    outline-offset: 0.1rem;
  }
`

export default CardContainer
