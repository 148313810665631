import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

const sharedButtonStyle = css`
  align-items: center;
  border-radius: var(--border-radius-2);
  box-shadow: var(--shadow-tight);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font: 600 var(--fontSize-1) / 1.5 var(--font-openSans);
  letter-spacing: var(--letterSpacing-1);
  margin: 1rem;
  padding: var(--spacing-2) var(--spacing-4);
  text-decoration: none;
  transition: transform 200ms ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: min-content;
  will-change: transform;
  @media (min-width: 480px) {
    transition:
      transform 200ms ease-in-out,
      filter 200ms ease-in-out,
      box-shadow 200ms ease-in-out;
    &:focus,
    &:hover {
      filter: brightness(120%);
      box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.05);
    }
  }
  &:active {
    transform: scale(0.97);
  }
`

const SolidBrandButton = css`
  ${sharedButtonStyle}
  background-color: var(--color-primary-700);
  border: 2px solid var(--color-primary-400);
  color: var(--color-greyscale-100);
`

export const HollowBrandButton = styled.button`
  ${sharedButtonStyle}
  background-color: transparent;
  border: 0.2rem solid ${props => props.theme.primary700to600};
  color: ${props => props.theme.black300greyscale200};
  justify-content: center;
  padding: 0.35rem var(--spacing-4);
`

export const BrandButton = styled.button`
  ${SolidBrandButton}
`

export const BrandLink = styled(Link)`
  ${SolidBrandButton}
`
