/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const BioContainer = styled.div`
  display: flex;
  margin: auto;
  padding: 0 2rem 2rem 2rem;
  max-width: 50rem;
`

export default function Bio(): any {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <BioContainer className="bio">
      {Boolean(author?.name) && (
        <p>
          Written by <strong>{author.name}</strong> {author?.summary ?? null}{' '}
          <a href={`https://twitter.com/${social?.twitter ?? ''}`}>You should follow them on Twitter</a>
        </p>
      )}
    </BioContainer>
  )
}
