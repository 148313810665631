export const POST_TYPE_CONSTANTS = {
  events: {
    PAGE_TITLE: 'Events',
    SECTION_TITLE: 'Event Calender',
    SUBTITLE: 'Explore cultural anomalies',
    DESCRIPTION:
      'From wild desert parties to explosive mountain-side firework festivals, explore unique cultural events from across the planet. If you need an excuse to travel, look no further.',
    DESCRIPTION_SHORT:
      'Explore unique cultural events across the planet. If you need an excuse to travel, look no further.',
  },
  articles: {
    PAGE_TITLE: 'Articles',
    SECTION_TITLE: 'Latest Articles',
    SUBTITLE: 'Browse our most recent posts',
    DESCRIPTION:
      'Dive into our latest travel stories, tips, and experiences. Our collection of articles share real adventures, helpful insights, and travel inspiration.',
    DESCRIPTION_SHORT: 'Dive into our latest posts to find travel stories, useful tips, and exciting experiences.',
  },
  routes: {
    PAGE_TITLE: 'Routes',
    SECTION_TITLE: 'Travel Routes',
    SUBTITLE: 'Find your next big adventure',
    DESCRIPTION:
      'From traditional backpacker routes and beyond, there are endless options for traversing the planet. Find a new adventure or get inspiration for your next trip.',
    DESCRIPTION_SHORT:
      'From classic backpacker routes and beyond, delve into the endless options for traversing the planet.',
  },
}

export const MONTHS_LONG = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
