import styled, { css } from 'styled-components'

const FilterMenuShared = css`
  align-items: center;
  background-color: ${props => props.theme.greyscale100secondary800};
  color: ${props => props.theme.black300greyscale200};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  letter-spacing: var(--letterSpacing-2);
  margin: 0;
  text-decoration: none;
  transition:
    color 300ms ease-in-out,
    background-color 300ms ease-in-out;
  width: 100%;
  @media (min-width: 480px) {
    &:hover {
      filter: brightness(1.15);
    }
    &:focus {
      filter: brightness(1.15);
      outline: 2px solid ${props => props.theme.primary700to300};
      outline-offset: -0.1rem;
    }
  }
`

export const FilterCategory = styled.button`
  ${FilterMenuShared}
  border: none;
  font: 700 var(--fontSize-2) / 1.2 var(--font-montserrat);
  padding: 0.5rem 1rem;
`

export const FilterItem = styled.label`
  ${FilterMenuShared}
  font: 400 var(--fontSize-1) / 1.2 var(--font-montserrat);
  padding: 0.3rem 2rem;
`
