import { useState, useEffect, useMemo } from 'react'

interface WindowSize {
  width: number
}

enum BreakpointCategory {
  CATEGORY_1 = 1,
  CATEGORY_2,
  CATEGORY_3,
  CATEGORY_4,
  CATEGORY_5,
}

const useWindowSize = (): WindowSize => {
  const isClient = typeof window === 'object'
  const [windowSize, setWindowSize] = useState({
    width: isClient ? window.innerWidth : 0,
  })

  const getCategory = useMemo(
    () =>
      (width: number): BreakpointCategory => {
        if (width < 480) {
          return BreakpointCategory.CATEGORY_1
        } else if (width < 768) {
          return BreakpointCategory.CATEGORY_2
        } else if (width < 980) {
          return BreakpointCategory.CATEGORY_3
        } else if (width < 1200) {
          return BreakpointCategory.CATEGORY_4
        } else {
          return BreakpointCategory.CATEGORY_5
        }
      },
    []
  )

  useEffect(() => {
    if (isClient) {
      let animationFrameId: number
      const handleResize = (): void => {
        animationFrameId = window.requestAnimationFrame(() => {
          const newWidth = window.innerWidth
          const currentCategory = getCategory(windowSize.width)
          const newCategory = getCategory(newWidth)
          if (newCategory !== currentCategory) setWindowSize({ width: newWidth })
        })
      }

      const resizeObserver = new ResizeObserver(() => {
        handleResize()
      })

      resizeObserver.observe(document.body)

      return () => {
        resizeObserver.disconnect()
        window.cancelAnimationFrame(animationFrameId)
      }
    }
  }, [isClient, getCategory, windowSize.width])

  return windowSize
}

export default useWindowSize
