import styled from 'styled-components'

export const HeaderDescription = styled.p`
  color: ${props => props.theme.black400greyscale300};
  font: 400 var(--fontSize-0) / 1.2 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-0);
  margin: 1rem auto;
  max-width: 30rem;
  text-align: center;
  transition: color 300ms ease-in-out;
`

export default HeaderDescription
